
.subbar-content {
  margin-left: 5%;
}
img {
  height: 40px;
}

.avatar-image {
  &:hover {
    transform: scale(1.1);
  }
}
