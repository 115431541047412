
#chats {
  height: 100%;
  background: #fff;
}

.full-height {
  min-height: 100svh;
}

#chat-subbar {
  border-bottom: 1px solid #ccc;
  background: #fff;
  height: 70px;
}

@media only screen and (max-width: 1800px) and (min-width: 769px) {
  .is-hidden-when-mobile {
    display: none;
  }
}

.chat-item {
  position: relative;
  border-bottom: 1px solid #ccc;
  padding: 10px 20px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2rem;

  &.is-warning {
    color: #ff4d00;
    border-bottom-color: #ff4d00;
    background-color: #ffeee4;
    height: 80px;
  }
}
