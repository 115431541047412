
.chat-item {
  position: relative;
  border-bottom: 1px solid #ccc;
  padding: 10px 20px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2rem;

  .persona-avatar {
    height: 40px;
    width: 40px;
    margin-right: 20px;
    background: #f0f0f0;
    border-radius: 10px;
  }

  .connect-chat {
    position: absolute;
    right: 2px;
  }

  &.selected {
    background-color: #c8f2fe;
    border-color: #23b1de;
  }

  .menu-icon {
    position: relative;
    cursor: pointer;

    .dropdown-menu {
      position: absolute;
      top: 100%;
      right: 0;
      background: white;
      border: 1px solid #ccc;
      border-radius: 5px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      z-index: 1000;

      button {
        padding: 10px;
        border: none;
        background: none;
        cursor: pointer;
        text-align: left;
        width: 100%;

        &:hover {
          background: #f0f0f0;
        }
      }
    }
  }
}

.menu-icon {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s, visibility 0.5s;
}

.chat-item:hover .menu-icon {
  opacity: 1;
  visibility: visible;
}

.w-100 {
  width: 100%;
}
