
::v-deep .field-label {
  display: none;
}


::v-deep .single-input-container {
  position: relative;
  z-index: 1;
  margin: 6px;
  @media (max-width: 768px) {
    margin: 1px;
  }
}

.simple-otp-input {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

